/*
 * @Author: your name
 * @Date: 2020-04-08 14:15:25
 * @LastEditTime: 2020-04-25 09:36:40
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \h5-share-react\src\api\config.js
 */
import axios from "axios";

// export const baseUrl = "http://metisapi.hoyar.com.cn/";
// export const baseUrl = "http://matsz.idankee.com/";
export const baseUrl = "https://metisapi.imoreme.com/";

// axios的实例及拦截器配置
const axiosInstance = axios.create({
    baseURL: baseUrl
});

axiosInstance.interceptors.response.use(
    res => res.data,
    err => {
        console.log(err, "网络错误");
    }
);

export {axiosInstance};
