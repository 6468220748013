import React from 'react';
import './index.scss';

import {titleIsShow, glStyle} from "../../plugIn/uils";
import Home_pic_Model from "../../static/img/Home_pic_Model@2x.png"
import Home_Flow from "../../static/img/Home_Flow@2x.png"
import Home_Flow_Point from "../../static/img/Home_Flow_Point@2x.png"
import Home_button from "../../static/img/Home_button@2x.png"

export default class AboutUs extends React.Component {

    titleIsShow = titleIsShow("一键识斑");

    componentDidMount() {
        document.title = this.titleIsShow.title;
        glStyle();
        window.laydate.render({
            elem:'#deadline'
            ,type:'date'//默认为date
            ,trigger:'click'//默认为click，即点击后出现日历框
        })
    }

    _ImgUpdate(target) {
        var reader = new FileReader(), file = target.currentTarget.files[0];
        reader.readAsDataURL(file);
        reader.onloadend = function (evt) {
            // console.log(evt.target.result);
            window.sessionStorage.setItem('key', evt.target.result);
            window.location.replace('detect');
        }
    }

    layer(e) {
        console.log(e, window.laydate)
    }

    render() {

        let {docHeight, display} = this.titleIsShow;
        this.titleIsShow.home_pic_style = {
            width: docHeight * 0.48 + "px",
            marginTop: `calc(${docHeight * 0.26}px - 2.445rem)`
        };

        this.titleIsShow.pop_up_style = {
            top: `${docHeight * 0.22}px`
        };

        return (
            <div id="index" style={{height: docHeight + "px"}}>
                <div className="title" style={{display: display}}>一键识斑</div>
                <img className="home_pic" src={Home_pic_Model} alt="" style={this.titleIsShow.home_pic_style}/>
                <div className="pop_up" style={this.titleIsShow.pop_up_style}>
                    <img className="pop_up_img" src={Home_Flow} alt=""/>
                    <div className="pop_up_content"><img src={Home_Flow_Point} alt=""/><span
                        className="text">Best Area</span></div>
                    <div className="pop_up_content Serious"><img src={Home_Flow_Point} alt=""/><span
                        className="text">Serious area</span></div>
                    <div className="pop_up_content Charm"><span className="Charm_text">Charm value</span><span
                        className="value">95</span></div>
                </div>

                <input type="text" className={"layui-input"} id={"deadline"} onClick={e => this.layer(e)}/>
                <div className="clues">请摘下眼镜露出额头并拍摄正脸</div>
                <input type="file" id="file" accept="image/*" style={{display: "none"}} ref={(input) => {
                    this.imgFile = input
                }} onChange={(input) => this._ImgUpdate(input)}/>
                <img className="phoBtn btn" src={Home_button} alt="" onClick={() => this.imgFile.click()}/>
            </div>
        )
    }
}
