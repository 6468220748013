import {axiosInstance} from "./config";

export const getFaceRequest = (
    formData
) => {
    return axiosInstance.post('/f/faceDetect/webExecute', formData,{headers:{'Content-Type':'multipart/form-data'}})
    // .then(function (response) {
    //     console.log(response);
    // })
    // .catch(function (error) {
    //     console.log(error);
    // });
};
export const getWebFaceDetect = (
    formData
) => {
    return axiosInstance.post('/f/faceDetect/getHwebFaceDetect', formData)
};

export const getWebMetisAPI = (
    formData
) => {
    return axiosInstance.post('/api/web/share/shareAiDiagnosisReport', formData)
};
