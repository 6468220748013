import img_icon from "../static/img_icon";
import ReactEcharts from "echarts-for-react";
import html2canvas from "html2canvas";
import layer from "./layer_mobile/layer";
import React from "react";
import intl from 'react-intl-universal';
import { PhotoConsumer, PhotoProvider } from "react-photo-view";
window.onload = function () {
    // 阻止双击放大
    var lastTouchEnd = 0;
    document.addEventListener('touchstart', function (event) {
        if (event.touches.length > 1) {
            event.preventDefault();
        }
    });
    document.addEventListener('touchend', function (event) {
        var now = (new Date()).getTime();
        if (now - lastTouchEnd <= 300) {
            // event.preventDefault();
        }
        lastTouchEnd = now;
    }, false);
    // 阻止双指放大
    document.addEventListener('gesturestart', function (event) {
        event.preventDefault();
    });
};

/**
 * @Description: 检测系统
 */
export function system() {
    document.body.classList = "h5 " + queryString("language");
    return {
        docWidth: document.documentElement.clientWidth,
        docHeight: document.documentElement.clientHeight,
        fontSize: document.documentElement.clientWidth / 375 * 18
    }
}

/**
 * @Description: 全局字体
 */
export function glStyle() {
    const style = window.document.createElement('style');
    style.type = "text/css";
    style.innerHTML = `html{font-size:${titleIsShow().fontSize}px}`;
    window.document.head.appendChild(style);
}

export function versions() {
    var u = navigator.userAgent
    // app = navigator.appVersion;
    // console.log(u,u.match(/MicroMessenger/i)[0]);
    return {//移动终端浏览器版本信息
        trident: u.indexOf('Trident') > -1, //IE内核
        presto: u.indexOf('Presto') > -1, //opera内核
        webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
        gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') === -1, //火狐内核
        mobile: !!u.match(/AppleWebKit.*Mobile.*/) || !!u.match(/AppleWebKit/), //是否为移动终端
        ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
        android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或者uc浏览器
        iPhone: u.indexOf('iPhone') > -1 || u.indexOf('Mac') > -1, //是否为iPhone或者QQHD浏览器
        iPad: u.indexOf('iPad') > -1, //是否iPad
        webApp: u.indexOf('Safari') === -1, //是否web应该程序，没有头部与底部
        weChat: u.indexOf('MicroMessenger') > -1
    };
}


/**
 * @Description: 是否显示头部
 */
export function titleIsShow(title) {
    let { weChat } = versions(), { docHeight, fontSize } = system(),
        option = {
            display: "block",
            title,
            docHeight,
            fontSize
        }

    weChat && (option.display = "none");

    return option
}


/**
 * @Description: 获取浏览器地址带参
 */
export function queryString(t, e) {
    var n, i = new RegExp("(^|&)" + t + "=([^&]*)(&|$)");
    return null != (n = e ? e.match(i) : window.location.search.substr(1).match(i)) ? unescape(n[2]) : null
}

/**
 * @Description: 头部
 */
export function Avatar(img, totalScore, type, logo, avatarBox) {
    totalScore = totalScore / 100 * avatarBox.svgPer;

    function initAvatarAnimation(totalScore, avatarBox) {
        const runkeyframes = `@keyframes strokeDasharray{
                                      0%{ stroke-dasharray: 0,345.625; }
                                      100%{ stroke-dasharray: ${totalScore},${avatarBox.svgPer};}
                                }`
        const style = window.document.createElement('style');
        style.innerHTML = runkeyframes;
        window.document.head.appendChild(style);
    }

    return (
        <>
            <img className="reportTopFlow" src={img_icon.avatarBg} alt="" />
            <div className="cusLogo"><img src={logo} alt="" /></div>
            <img className="instrumentLogo" style={{ display: type ? " block" : " none" }}
                src={require(`../static/img/${type}-icon@2x.png`)} alt="" />
            <div className="avatarBox">
                <div className="avatarLogo">
                    <img src={img} alt="" onLoad={initAvatarAnimation(totalScore, avatarBox)} />
                </div>
                <svg id="" className="svg" xmlns="http://www.w3.org/200/svg" height="150" width="110">
                    <circle cx="55" cy="55" r="50" fill="none" stroke="#2c2e3c" strokeLinecap="round" />
                    <circle className="progress_circle" id="J_progress_bar" fill="none" strokeLinecap="round"
                        strokeDasharray={totalScore + "," + avatarBox.svgPer}
                        stroke="#30A1E8" />
                </svg>
            </div>
        </>
    )
}

/**
 * @Description: 评分详情
 */
export function ScoringDetails(scoring, totalScore) {
    let boxBgClass = '', text = '', arr = [{ name: intl.get("总分"), value: Math.round(totalScore) }, {
        name: intl.get("肤龄"),
        value: scoring.skinAge
    },
    { name: intl.get("颜值"), value: scoring.levelEarance }, {
        name: intl.get("肤色"),
        value: scoring.emotion
    }].filter(item => item.value);
    if (arr.length > 1) {
        boxBgClass = 'box_bg_multiple'
        text = intl.get("综合得分代表肌肤的健康程度，得分越高代表肌肤状况越好。<br/>肤龄反映一个人的身体和生活状态，不一定等于生理年龄，可以通过护肤改变。");
    } else if (arr.length === 1) {
        boxBgClass = 'box_bg_single'
        text = intl.get("综合得分代表肌肤的健康程度，得分越高代表肌肤状况越好。");
    }

    return (<div className={"box_bg " + boxBgClass} data-title={text}>
        {
            arr.map((item, index) => {
                return (<div className="text_box" key={index}>
                    <div className="text">{item.value}<br /><span className="name">{item.name}</span></div>
                </div>)
            })
        }
    </div>)
}

/**
 * @Description: 依据 level 或者 degree 返回 轻中重 相应参数
 */
export function degreeFotmat(key) {
    let leftInitial = 1.986, leftThresHold = 2.933;

    if (key.toString().indexOf(intl.get("重度")) !== -1 || Number(key) > 2) {
        return {
            imgSrc: img_icon.beSolved,
            color: "#c72c3b",
            colorTag: "red",
            degree: intl.get('重度'),
            left: leftInitial + leftThresHold * 2 + 'rem;'
        }
    } else if (key.toString().indexOf("中度") !== -1 || key.toString().indexOf("干性") !== -1
        || key.toString().indexOf("油性") !== -1 || Number(key) === 2) {
        return {
            imgSrc: img_icon.beBadly,
            color: "#c56b2a",
            colorTag: "yellow",
            degree: intl.get('中度'),
            left: leftInitial + leftThresHold + 'rem;'
        }
    } else if (key.toString().indexOf("轻度") !== -1 || key.toString().indexOf("油性") !== -1 || Number(key) === 1) {
        return {
            imgSrc: img_icon.beBadly,
            color: "#c56b2a",
            colorTag: "yellow",
            degree: intl.get('轻度'),
            // left: 1.986 + 'rem;'
            left: leftInitial + 'rem;'
        }
    } else if (!Number(key)) {
        return {
            imgSrc: img_icon.beKeep,
            color: "#116397",
            colorTag: "blue",
            degree: intl.get('无'),
            // left: 1.986 + 'rem;'
            left: leftInitial + 'rem;'
        }
    }
}


/**
 * @Description: 皮肤综合问题分析 .sort((a, b) => a.predicted - b.predicted)
 */
export function SkinAnalysis(resData) {
    return (
        <>
            <div className="analysis_list">
                {
                    resData.analysis.map((item, index) => {
                        let degree = degreeFotmat(item.degree);
                        return (
                            <a className="analysis_item" key={index} href={"#" + item.tag}>
                                <img className="problem_icon" src={img_icon[item.tag]} alt={item.tag} />
                                <div className="degree_icon"><img src={degree.imgSrc} alt="" /></div>
                                <div className="instructions">
                                    <span className="name">{item.name}</span>
                                    <span className="degree">{item.degree}</span>
                                </div>
                            </a>
                        )
                    })
                }
            </div>
            {ComprehensiveAnalysisResults(resData.scoring)}
        </>
    )
}

function analysis_list(resData) {
    return (
        <div className='analysis_list'>
            {
                resData.analysis.map((item, index) => {
                    return (
                        <a className="analysis_item" key={index} href={"#" + item.tag}>
                            <img className="problem_icon" src={img_icon[item.tag]} alt={item.tag} />
                            <figure>
                                <div className="name">{item.name}<span
                                    className="predicted">{item.predicted}</span>
                                </div>
                                <progress className="progress" value={item.predicted}
                                    max="100">{intl.get("您的浏览器不支持progress元素")}
                                </progress>
                            </figure>
                        </a>
                    )
                })
            }
        </div>

    )
}

/**
 * @Description: 饼图渲染

 * @date 2019-09-04
 */
export function PieRendering(el, resData) {
    var canvas = document.getElementById(el), isText = false, { docWidth, fontSize } = system();
    canvas.width = 18.593 * fontSize * 2;
    canvas.height = 10 * fontSize * 2;
    canvas.style.width = canvas.width / 2 + 'px';
    canvas.style.height = canvas.height / 2 + 'px';
    var radius = docWidth * 0.42933; // 饼图 半径
    if (el === "canvas_text") {
        isText = true
    }
    var ctx = canvas.getContext('2d'),
        color = ['#f2f2f3', '#929293', '#626263', '#4b4b4c', '#646466'],
        data = resData.map(function (item, index) {
            return { value: item.prop / 100, color: color[index], title: item.name }
        })
    // data = resData.map((item, index) => {
    //     return { value: item.prop / 100, color: color[index], title: item.name }
    // });
    // data = [
    //     {value: .1,color:color[0],title:"1:"},
    //     {value: .1,color:color[1],title:"2:"},
    //     {value: .1,color:color[2],title:"3:"},
    //     {value: .1,color:color[3],title:"4:"},
    //     {value: .1,color:color[4],title:"5:"},
    //     {value: .1,color:color[5],title:"6:"},
    //     {value: .1,color:color[6],title:"7:"},
    //     {value: .1,color:color[7],title:"8:"},
    //     {value: .1,color:color[8],title:"9:"},
    //     {value: .1,color:color[9],title:"10:"},
    // ]
    //定义起始角度
    var tempAngle = -90;
    //定圆心位置
    var x0 = canvas.width / 2, y0 = 8 * fontSize, txtAngleList = [];

    //定半径长度
    //从-90度开始绘制
    for (var i = 0; i < data.length; i++) {
        ctx.beginPath();
        ctx.lineWidth = 1;//线条的宽度
        ctx.strokeStyle = "rgba(0,0,0,0)";//线条的颜色
        // if(!isText){ctx.strokeStyle = "#fff"}
        ctx.moveTo(x0, y0);
        //当前扇形角度
        var angle = data[i].value * 360;
        //当前扇形起始绘制弧度
        var startAngle = tempAngle * Math.PI / 180;
        //当前扇形借结束绘制弧度
        var endAngle = (tempAngle + angle) * Math.PI / 180;

        //绘制扇形
        ctx.ellipse(x0, y0, radius, radius * 0.3, 0, startAngle, endAngle);

        ctx.stroke();
        //填充扇形
        ctx.fillStyle = data[i].color;
        ctx.fill();
        if (!isText) {
            //计算文字要放的角度
            var txtAngle = tempAngle + 1 / 2 * angle;

            //绘制文字
            //提取要绘制的文字内容
            var txt = data[i].title + ' ' + Math.round(data[i].value * 100) + '%';

            //计算文字要放的坐标位置
            var x, y, x_longer = fontSize * 5, y_longer = x_longer;
            x = x0 + Math.cos(txtAngle * Math.PI / 180) * (radius + 50);
            // if(x<100&&y<100){x-=10};
            y = y0 + Math.sin(txtAngle * Math.PI / 180) * ((radius + 50) * 0.3);

            //如果文字在圆形的左侧，那么让文字 对齐方式为 文字结尾对齐当前坐标。
            if (txtAngle > 90 && txtAngle < 270) {
                ctx.textAlign = 'end';
            }
            ctx.beginPath();       // Start a new path
            ctx.lineWidth = 1;//线条的宽度
            ctx.strokeStyle = "rgba(236,236,236,0.94)";//线条的颜色

            txtAngleList.push(txtAngle);
            // console.log(txtAngle,data[i].title);
            if (txtAngleList.length > 0) {
                // if(txtAngle>20 && txtAngle<160)
                if ((txtAngle < 20 && txtAngle > -40) || (txtAngle < 240 && txtAngle > 160)) {
                    x_longer = x_longer * 0.55;
                    y_longer = y_longer * 0.55
                } else {
                    if ((txtAngle > 20 && txtAngle <= 40) || (txtAngle > 130 && txtAngle <= 160)) {
                        x_longer = x_longer * 0.8;
                        y_longer = y_longer * 0.8
                    }
                    if ((txtAngle > 40 && txtAngle <= 60) || (txtAngle > 120 && txtAngle <= 130)) {
                        x_longer = x_longer * 0.9;
                        y_longer = y_longer * 0.9
                    }

                    if ((txtAngle > 60 && txtAngle <= 75) || (txtAngle > 105 && txtAngle <= 120)) {
                        x_longer = x_longer * 1.5;
                        y_longer = y_longer * 1.5
                    }
                    if (txtAngle > 75 && txtAngle <= 105) {
                        x_longer = x_longer * 2.8;
                        y_longer = y_longer * 2.8
                    }
                }
                if (txtAngleList[i] - txtAngleList[i - 1] < 20) {
                    x_longer = x_longer * 2;
                    y_longer = y_longer * 2;
                }
                x = x0 + Math.cos(txtAngle * Math.PI / 180) * (radius + x_longer);
                y = y0 + Math.sin(txtAngle * Math.PI / 180) * ((radius + y_longer) * 0.3);
            }

            ctx.moveTo(x0, y0);
            ctx.lineTo(x, y);  // Draw a line to (150, 100)
            // console.log(data[i].title.length * fontSize);
            // let lineLength = (data[i].title.length + 3) * fontSize;
            let lineLength = 100;
            ctx.lineTo((x >= x0 ? x + lineLength : x - lineLength), y);
            ctx.stroke();          // Render the path
            //文字绘制到扇形旁边
            // ctx.measureText(txt).width;
            // ctx.scale(100/canvas.width, 1);
            ctx.font = "25px Arial";
            // ctx.textBaseline = "bottom";
            ctx.fillStyle = "#fff";
            ctx.fillText(txt, x, y - 10);
        }

        //当前扇形结束绘制角度，即下一个扇形开始绘制角度
        tempAngle += angle;
    }
    document.querySelector(".canvas_bg").src = img_icon.canvas_bg;
    return new Promise(resolve => {
        resolve({ canvas: canvas });
    });
}

function pie_analysis(analysisFlag) {
    return (<div className={analysisFlag ? 'pie_analysis' : 'hide'}>
        <canvas id="canvas_text" className="canvas_text" />
        <canvas id="canvas_pie" className="canvas_pie" />
        <img className="canvas_bg" src="" alt="" />
        <span className="canvas_title">{intl.get("各皮肤问题占比")}</span>
    </div>)
}

export function SkinAnalysisDetectorReport(resData) {
    let analysisFlag = resData.type === 'DBQ3-1' ? true : false;
    return (
        <>
            {analysis_list(resData)}
            {pie_analysis(analysisFlag)}
        </>
    )
}

/**
 * @Description: 综合分析结果
 */
export function ComprehensiveAnalysisResults(data) {
    let { name, centent } = data;
    return (
        <div className="ComprehensiveAnalysisResults">
            <div className="title"><span className="line" /><span className="text">{name}</span><span
                className="line" /></div>
            <div className="centent_text">{centent}</div>
        </div>
    )
}


/**
 * @Description: 皮肤综合分析外壳
 */
export function ItemShell(item, SkinQuestionItemHtml) {
    let degree = { colorTag: "blue" }, name = item.name;
    if (!Array.isArray(item)) {
        if (item.tag !== "colorSkin" && item.tag !== "skin") {
            degree = degreeFotmat(item.degree)
            name = `<img src="${degree.imgSrc}" alt="">` + item.name
        }
    }
    return (
        <>
            <div className={"top_title " + degree.colorTag} id={item.tag}
                dangerouslySetInnerHTML={{ __html: Array.isArray(item) ? intl.get("皮肤问题分析") : name }} />
            <div className="centent">
                {SkinQuestionItemHtml}
            </div>
        </>
    )
}

export function ItemShellDetectorReport(item, hideFlag, type, SkinQuestionItemHtml) {
    let degree = { colorTag: "blue" }, name = item.name;

    if ((hideFlag && type === 'Skin_analysis') || !hideFlag) {
        return (
            <>
                <div className={"top_title " + degree.colorTag} id={item.tag}
                    dangerouslySetInnerHTML={{ __html: Array.isArray(item) ? intl.get("皮肤问题分析") : name }} />
                <div className={'centent'}>
                    {SkinQuestionItemHtml}
                </div>
            </>
        )
    } else {
        return (
            <>
                <div className="centent">
                    {SkinQuestionItemHtml}
                </div>
            </>
        )
    }

}


/*
 *@description:时间转换
 *@author: 张莹
 *@date: 2020-05-25 15:11:43
 *@version: V1.0.5
*/
function formatDate(date) {
    date = new Date(date);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    var d = date.getDate();
    var h = date.getHours();
    var m1 = date.getMinutes();
    var s = date.getSeconds();
    m = m < 10 ? ("0" + m) : m;
    d = d < 10 ? ("0" + d) : d;
    h = h < 10 ? ("0" + h) : h;
    m1 = m1 < 10 ? ("0" + m1) : m1;
    s = s < 10 ? ("0" + s) : s;
    return y + "-" + m + "-" + d + " " + h + ":" + m1 + ":" + s;
}


// 绘制毛孔图和黑头图
export function poreCanvansImg(type, that) {
    let data = that.state.resData;
    // console.log(data)
    let poreArr = [];
    let blackheadArr = [];
    let poreImg = data.cgImgList[1].imgurl + '?jinhaoyang_djm=' + new Date().getTime();
    let blackheadImg = data.cgImgList[0].imgurl + '?jinhaoyang_djm=' + new Date().getTime();
    data.question.forEach(el => {
        if (el.tag === 'pore') {
            poreArr = JSON.parse(data.drawing.pore)
        }
        if (el.tag === 'blackhead') {
            let arr = JSON.parse(data.drawing.blackhead)
            blackheadArr = arrTrans(2, arr)//一维数组处理为二维数组
        }
    })
    return (
        <div>
            {
                type === 'pore' ? (

                    <>
                        {
                            <div>
                                <img src={poreImg} alt="" crossOrigin="anonymous" />
                            </div>

                        }
                        {
                            poreArr.map((item, index) => {
                                return (
                                    <i className="pore" key={index} style={{ top: item[0] / 5.95 / 20 + 'rem', left: item[1] / 5.95 / 20 + 'rem' }}></i>
                                )
                            })
                        }
                    </>
                ) : (
                        <>
                            {
                                blackheadArr.map((item, index) => {
                                    return (
                                        <i className="blackhead" key={index} style={{ left: item[0] / 5.95 / 20 + 'rem', top: item[1] / 5.95 / 20 + 'rem' }}></i>
                                    )
                                })
                            }
                            <div>
                                <img src={blackheadImg} alt="" crossOrigin="anonymous" />
                            </div>
                        </>
                    )
            }
        </div>
    )
}


export function exportImg(e, type, that) {
    let data = that.state.resData
    html2canvas(e, {
        useCORS: true,
        logging: false,
        allowTaint: true,
        taintTest: false,
        backgroundColor: null
    }).then(canvas => {
        // 毛孔绘制图
        if (type === 'poreBox') {
            let imgUrl = canvas.toDataURL('image/png');
            data.question.forEach(el => {
                if (el.tag === 'pore') {
                    el.imgurl = imgUrl
                }
            })
            // 替换轮播图中的毛孔图片
            data.cgImgList.forEach(el => {
                if (el.name === '毛孔图') {
                    el.imgurl = imgUrl
                }
            })
        }
        if (type === 'blackheadBox') {
            // 黑头绘制
            let imgUrl = canvas.toDataURL('image/png');
            data.question.forEach(el => {
                if (el.tag === 'blackhead') {
                    el.imgurl = imgUrl
                }
            })
            data.cgImgList.forEach(el => {
                if (el.name === '黑头图') {
                    el.imgurl = imgUrl
                }
            })
        }

    })
}



export function arrTrans(num, arr) { // 一维数组转换为二维数组
    const iconsArr = []; // 声明数组
    arr.forEach((item, index) => {
        const page = Math.floor(index / num); // 计算该元素为第几个素组内
        if (!iconsArr[page]) { // 判断是否存在
            iconsArr[page] = [];
        }
        iconsArr[page].push(item);
    });
    return iconsArr;
}

export function getBase64Image(img) {
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, img.width, img.height);
    // var ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase();
    var dataURL = canvas.toDataURL("image/jpeg");
    return dataURL;
}


/**
 * @Description: 分享页面构造
 */
export function Share_pic(res, avatarShare, that, isReport) {
    /**
     * @Description: 分享页面构造 参数
     */

    // 时区换算
    let nowTime = new Date();
    let offset = nowTime.getTimezoneOffset() / 60; //获取当前时区差
    let sdtime1 = new Date(Date.parse(res.createDate.replace(/-/g, "/")));
    let sdtime2 = sdtime1.setHours(sdtime1.getHours() - 8 - offset)//小时
    let shareTime = formatDate(sdtime2)

    let getOption = (resData) => {
        let indicator = resData.analysis.map((item) => {
            return { name: item.name, max: 100, nameLength: item.name.length }
        }), optionValue = resData.analysis.map(item => item.predicted),
            // optionValueLengths = indicator.map(item=>{return item.nameLength}),
            // nameWidth = ['50%','55%','50%','45%'],
            radarCenter = ['50%', '55%'],
            radius = "50%";
        return {
            backgroundColor: 'rgba(0,0,0,0)',
            // tooltip: {
            //     trigger: 'axis',
            //     show: true
            // },
            radar: {
                indicator: indicator,
                radius: radius,
                center: radarCenter,
                shape: 'circle',
                splitNumber: 3,
                name: {
                    textStyle: {
                        color: '#7d8498',
                        fontSize: 10
                    }
                },
                splitArea: {
                    areaStyle: {
                        color: ['rgba(255,255,255,0.1)',
                            'rgba(255,255,255,0.03)', 'rgba(255,255,255,0.1)',
                            'rgba(255,255,255,0.03)'
                        ]
                    }
                },
                splitLine: {
                    lineStyle: {
                        color: [
                            '#6b6b6b', '#6b6b6b',
                            '#6b6b6b', '#6b6b6b',
                            '#6b6b6b', '#6b6b6b'
                        ].reverse(),
                        width: 1
                    }
                },
                axisLine: {
                    lineStyle: {
                        color: 'rgba(238,238,238, 0.2)'
                    }
                }
            },
            series: {
                name: '问题分析',
                type: 'radar',
                // tooltip: {
                //     trigger: 'item'
                // },
                data: [{
                    value: optionValue
                }],
                symbol: 'circle',
                symbolSize: 8,
                itemStyle: {
                    normal: {
                        color: '#eaeaea',
                        borderColor: 'rgba(87,201,255,0.2)',
                        borderWidth: 8,
                    }
                },
                areaStyle: {
                    normal: {
                        color: 'rgba(13, 178, 255, 0.49)',
                    }
                },
                lineStyle: {
                    normal: {
                        color: 'rgba(13, 178, 255, 1)',
                        width: 1
                    }
                },

            },
            animation: false
        };
    };
    return (
        <>
            <div className="top">
                <div className="left">
                    <div className="title">{intl.get("AI检测报告")}</div>
                    <div className="time">{isReport && shareTime}</div>
                    <div className="score"><span className="text">{intl.get("综合得分")}</span><span
                        className="value">{Math.round(res.totalScore)}</span></div>
                    <div className="beat">{intl.get("击败全国")} {res.rankPer} {intl.get("的人")}</div>
                </div>
                <div className="right">
                    {
                        that.language.includes("zh") && <ReactEcharts
                            option={getOption(res)}
                            style={{ height: '10rem', width: '10rem' }}
                        // notMerge={true}
                        // lazyUpdate={true}
                        // theme={"theme_name"}
                        // onChartReady={e => showShareImg(e, that)}
                        // onEvents={EventsDict}
                        />
                    }
                </div>
            </div>
            <div className="center">
                <img className="avatar" src={avatarShare} alt="" crossOrigin="anonymous" />
                <div className="score_text">
                    <span className="the"><i></i>{intl.get("本次得分")}</span>
                    <span className="national"><i></i>{intl.get("全国平均值")}</span>
                </div>
                <div className="score_process_box" style={{ width: `calc(100% - ${1.1952 * 2}rem)` }}>
                    {
                        res.analysis.map((item, index) => {
                            return (
                                <div className="score_process" key={index}>
                                    <div className="value" />
                                    <div className="top_process">
                                        <div className="the" />
                                        <div className="the_bg"
                                            style={{ height: item.predicted / 100 * 4.667 + "rem" }} />
                                        <div className="national" />
                                        <div className="national_bg"
                                            style={{ height: item.avgScore / 100 * 4.667 + "rem" }} />
                                    </div>
                                    <div className="text">{item.name}</div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="footer">
                {/*<img className="qc" src={res.skinQr} alt=""/>*/}
                <img className="Badge-icon" src={require(`../static/img/${res.type}-icon@2x.png`)} alt="" />
                <figure>
                    <div className="text">#{intl.get("AI解析肌肤密码")}#</div>
                    <img className="logo" src={res.logo} alt="" onLoad={event => showShareImg(event, that)} />
                </figure>
                {/*{*/}
                {/*    that.language.includes("zh") && <img className="Badge-icon" style={{marginTop: "-1rem"}}*/}
                {/*                                         src={require(`../static/img/${res.type}-icon@2x.png`)} alt=""/>*/}
                {/*}*/}
            </div>
        </>
    )
}




/**
 * @Description: 皮肤问题 渲染
 */
export function Skin_question_item(item, baseImage, ytfile, programQr, that, isReport, hideFlag) {

    let system = {};
    system.language = that.language;
    system.fontSize = that.titleIsShow.fontSize;
    system.viewLargerVersionClass = 'avatar_big';
    system.isReport = isReport
    // item.level = 0;

    item.degree = item.level;
    item.tagName = '';
    let tab = '',
        html = '',
        averageProbability = '',
        symptoms_nursing = '',
        number_conditions = '',
        degree = degreeFotmat(item.degree).degree,
        tagPadding = `padding: ${system.fontSize / 54.054}rem ${system.fontSize / 40.054}rem;`;

    /**
     * @Description: 平均概率

     * @date 2019-09-04
     */
    item.chance && item.chance.forEach((item_chance, index) => {
        if (!index) {
            averageProbability += `<div class="average_probability_title">${intl.get("您需要关注的重点问题")}</div>`
        }
        averageProbability += `<span class="average_probability_item">
            <span>${item_chance.name}</span> 
        </span>`
    });

    /**
     * @Description: 病症数量

     * @date 2019-09-04
     */

    if (item.number !== null || item.tag === "sensity") {
        function tagFun(type) {
            let text = '';
            switch (type) {
                case "spots":
                    text = intl.get('面占比');
                    break;
                case "stain":
                    text = intl.get('面占比');
                    break;
                case "wrinkle":
                    text = intl.get('条数');
                    break;
                case "sensity":
                    text = intl.get('程度');
                    break;
                case "pore":
                    if (system.device === "detector") {
                        text = intl.get('个数')
                    } else {
                        text = intl.get('数')
                    }
                    break;
                default:
                    text = intl.get('数');
                    break;
            }
            text = system.language.includes("zh") ? item.name + text : text;
            return text + (system.isIpad ? ":" : "");
        }

        number_conditions += `
                        <div class="icon_num">
                            <img src="${img_icon[item.tag]}" alt="">
                            <div class="number_conditions_num">
                                <span class="num">${tagFun(item.tag)}</span><span style="display: ${item.tag !== "sensity" && item.number && system.language === 'zh' ? "block" : "none"}">${item.number}</span>
                            </div>
                        </div>`;
        if (!item.number || !item.level) {
            number_conditions +=
                `<div class="number_conditions_degree" style="color: white;text-indent: 1rem;line-height: 2.639rem;font-size: 0.667rem">${intl.get("无")}</div>`;
        } else if (system.language === 'zh') {
            number_conditions +=
                `<div class="number_conditions_degree">
                    <img class="dianzan" src="${img_icon.dianzan}" alt="">
                    <img class="Worse" src="${img_icon.worse}" alt="">
                    <div class="one_bar"></div>
                    <!--<div class="two_bar"></div>-->
                    <div class="one_box"></div>
                    <div class="two_box"></div>
                    <div class="${system.language.includes('vl') ? 'vl-text' : 'text'}">
                        <span>${intl.get('轻度')}</span><span>${intl.get('中度')}</span><span>${intl.get('重度')}</span>
                    </div>
                    <div class="box" style="left:${degreeFotmat(item.level).left}"></div>
                  </div>`;
        } else if ((!item.number || !item.level) && system.language === 'en') {
            number_conditions +=
                `<div class="number_conditions_degree" style="color: white;text-indent: 1rem;line-height: 2.639rem;font-size: 0.667rem">${intl.get("无")}</div>`;
        } else if (system.language === 'en' && item.tag === 'sensity') {
            number_conditions +=
                `<div class="number_conditions_degree" style="color: white;text-indent: 1rem;line-height: 2.639rem;font-size: 0.667rem">${degreeFotmat(item.level).degree}</div>`;
        } else {
            number_conditions +=
                `<div class="number_conditions_degree" style="color: white;text-indent: 1rem;line-height: 2.639rem;font-size: 0.667rem">${item.number}</div>`;
        }
    }

    /**
     * @Description: 模特图渲染

     * @date 2019-10-28
     */
    switch (item.tag) {
        case 'skin':
            item.tagName = `<img class="" style="position: relative;z-index: 555" data-type="${item.tag}" src="${img_icon[item.tag + "Model"]}" alt="">`;
            item.comp[0].dermalogicatext.forEach((itemDermalogicatext) => {
                item.tagName += `<span style="${tagPadding}">
                                ${itemDermalogicatext.degree.indexOf("中") !== -1 ? itemDermalogicatext.degree.slice(0, 2) : "偏" + itemDermalogicatext.degree.slice(0, 1)}
                            </span>`
            });
            break;
        case 'colorSkin':
            // item.questionAvatarBg = '#1f2331';
            item.tagName = `
                        <div class="colorSkinBox">
                            <img class="" style="position: relative;z-index: 555" data-type="${item.tag}" src="${img_icon[item.tag + "Model"]}" alt="">
                            <i class="colorSkinMarking" style="left: ${(!system.isIpad ? 2.722 * 2 + 0.667 : 5.269333333333333 * 2 + 1.4645)}rem;background-image: url(${img_icon.colorSkinMarking});"></i>
                        </div>
                        <div class="colorSkinText">
                            <span>${intl.get("透白")}</span>
                            <span>${intl.get("象牙")}</span>
                            <span>${intl.get("自然")}</span>
                            <span>${intl.get("麦色")}</span>
                            <span>${intl.get("古铜")}</span>
                            <span>${intl.get("黝黑")}</span>
                        </div>
                        <div class="colorSkinContent">
                            ${intl.get("你拥有自然的肤色。平时注意做好防晒，保持充足的睡眠及适度的运动，加强新陈代谢。此外可以根据自己的皮肤状况选择VC、烟酰胺等具有美白、抗氧化或加速黑色素代谢功效的护肤产品。")}
                        </div>
                        `
            break
        default:
            item.tagName = `<img class="" style="position: relative;z-index: 555" data-type="${item.tag}" src="${img_icon[item.tag + "Model"]}" alt="">`;
            item.comp.length && Array.isArray(item.comp[0].dermalogicatext) ?
                item.comp[0].dermalogicatext.forEach((itemDermalogicatext) => {
                    item.tagName += `<span style="${tagPadding}">${itemDermalogicatext.name + "," + degreeFotmat(itemDermalogicatext.degree).degree}</span>`;
                }) :
                item.tagName += `<span style="${tagPadding}">${degree}</span>`;
            break
    }

    /**
     * @Description: 症状护理

     * @date 2019-09-04
     */
    item.comp.forEach((itemComp, index) => {
        let goods = '';
        // goods += `<div class="swiper-slide">${goodsItem(itemComp)}</div>`
        itemComp.goodsList.forEach((itemGoodsList, index) => {
            goods += `<div class="swiper-slide">`;

            itemGoodsList.forEach((goodsItem, i) => {
                goods += `<div class="goods_list">
                                <div class="goods">
                                    <a class="goods_img" onclick="preview(event)">
                                        <img src="${goodsItem.imageUrl}" alt="" />
                                    </a>
                                    <div class="goods_name">
                                        ${goodsItem.goodsname}
                                    </div>
                                </div>
                              </div>
                            `
            })

            goods += `</div>`

        });

        if (item.comp.length > 1) {
            // tab += `<span class="${!index ? "active" : ""}" data-index="${index}"><i>${itemComp.tagname}</i></span>`;
            tab += `
                     <span class="swiper-slide ${!index ? "active" : ""}"><i>${itemComp.tagname}</i></span>
                    `
        }

        //.sort((a, b) => b.level - a.level) <span class="indicatorsShow">${$.i18n.prop('指标说明')}<img class="promptBtn btn" src="${img_icon.prompt}" alt=""></span>
        const moreMaskHtml = `<div class="more_mask">
                    <img class="more_mask_img" src="${img_icon.moreMask}" alt="">
                    <div class="more_mask_button" onclick="removeMoreMask(event)">${intl.get("查看更多")}<img class="more_mask_down" src="${img_icon.moreMaskDown}" alt=""></div>
                </div>`;
        const dermalogicatextArray = function (dermalogicatext) {
            let o = '', arr = [];
            dermalogicatext.filter((itemDermalogicatext, index, array) => {
                if (itemDermalogicatext.degreeTag) {
                    if (arr.join().indexOf(itemDermalogicatext.degreeTag) === -1) {
                        arr.push(itemDermalogicatext.degreeTag);
                        return true;
                    }
                } else if (itemDermalogicatext.tag) {
                    if (arr.join().indexOf(itemDermalogicatext.tag) === -1) {
                        arr.push(itemDermalogicatext.tag);
                        return true;
                    }
                    // let nextItem = array[index + 1];
                    // return (item.tag === 'skin' ? itemDermalogicatext.degree !== (nextItem && nextItem.degree) : true);
                }
                return false;
                // console.log(itemDermalogicatext);
            }).forEach((itemDermalogicatext) => {
                let isSkin = itemDermalogicatext.degree.indexOf("肤质") !== -1,
                    isBlackEye = item.name.indexOf("黑眼圈") !== -1,
                    degree = degreeFotmat(itemDermalogicatext.degree);

                if (!isBlackEye) {
                    o += `<div class="nursing_suggestions_segmentation">
                                <div class="logo_name_degree">
                                    <img class="tag_logo" src="${img_icon.dermalogicatext[isSkin ? itemDermalogicatext.degreeTag : itemDermalogicatext.tag]}" alt="">
                                    <span class="tag_name">${isSkin ? itemDermalogicatext.degree : itemDermalogicatext.name}</span>`;
                    !isSkin && (o += `<span class="tag_degree" style="background: ${degree.color}">${degree.degree}</span>`);
                    o += `</div>
                                <div class="advice ${itemDermalogicatext.advice && itemDermalogicatext.advice.length > 150 ? "line-clamp-six" : ""}">${itemDermalogicatext.advice || ""}</div>
                                ${itemDermalogicatext.advice && itemDermalogicatext.advice.length > 150 ? moreMaskHtml : ''}
                             </div>`
                } else {
                    o = itemDermalogicatext.advice;
                }
            });
            return o
        };
        const { reasontext, dermalogicatext, improve, impact } = itemComp;
        const itemCompArr = [
            { name: intl.get('护理方法'), centent: dermalogicatext, class: "nursing_suggestions" },
            { name: intl.get('症状描述'), centent: reasontext, class: "symptoms_described" },
            {
                name: intl.get('症状影响'),
                centent: impact,
                class: "no_nursing_effect"
            },
            { name: intl.get('症状改善'), centent: improve, class: "improve_after_nursing" },
        ].filter(item => item.centent);
        if (itemCompArr.length || goods) {
            symptoms_nursing += `<div class="swiper-slide">`;
            itemCompArr.forEach((item, index) => {
                symptoms_nursing += `<div class="${item.class}">
                                    <strong class="name">${item.name}</strong>
                                    ${Array.isArray(item.centent) ?
                        `<div class="dermalogicatext">${dermalogicatextArray(item.centent)}</div>`
                        : `<div class="dermalogicatext ${item.centent.length > 150 ? "line-clamp-six" : ""}">${item.centent}</div>${item.centent.length > 150 ? moreMaskHtml : ''}`
                    }
                                 </div>`
            })
            symptoms_nursing += goods ? `<div id="goodsSwiper" class="g-swiper">
                                            <div class="swiper-container">
                                                <div class="swiper-wrapper">${goods}</div>
                                                <div class="swiper-pagination"></div>
                                            </div>
                                         </div>` : '';
            symptoms_nursing += `</div>`
        }
    });

    /**
     * @Description: 皮肤问题主体结构

     * @date 2019-09-04
     */
    // html = `<div class="skin_question_avatar">`;

    // if (item.indicatorsShow) {
    // html += questionMark({indicatorsShow: item.indicatorsShow})
    // }
    // if (item.name.indexOf('皮肤综合') !== -1) {
    //     html += `<img class="report_Icon_Badge" src="${img_icon.h5Icon}" alt="">`
    // }

    if (!system.isReport) {
        system.viewLargerVersionClass = "avatar";
    }

    if (system.isReport) {
        symptoms_nursing = `<div class="swiper-container" id="symptoms_nursing_swiper">
                                <div class="swiper-wrapper">
                                    ${symptoms_nursing}
                                </div>
                            </div>`
    }

    item.blackEyeActive && (html += item.blackEyeActive);
    window.removeMoreMask || (window.removeMoreMask = that.removeMoreMask.bind(that));
    window.preview || (window.preview = that.preview.bind(that));
    return (
        <>
            <div className={hideFlag ? 'hide' : 'skin_question_avatar'}>
                {
                    !system.isReport && <div className={"skin_question_model_box " + item.tag}
                        dangerouslySetInnerHTML={{ __html: item.tagName }} />
                }
                {
                    item.imgurl &&
                    <div className={system.viewLargerVersionClass + " " + item.tag}>
                        <PhotoProvider>
                            <PhotoConsumer className="photo-back-img" src={item.imgurl} intro={item.name} backgroundImage={(item.tag === "blackhead" && `url(${baseImage + "?x-oss-process=image/resize,w_200"})`)}>
                                <img className={"skin_question_avatar_img " + item.tag}
                                    style={{
                                        position: "relative",
                                        top: 0,
                                        left: 0,
                                        zIndex: 555,
                                        // backgroundImage: (item.tag === "blackhead" && `url(${baseImage + "?x-oss-process=image/resize,w_200"})`),
                                        // backgroundSize: "100% 100%"
                                    }}
                                    datatype={item.tag} src={item.imgurl}
                                    alt={item.name}
                                />
                            </PhotoConsumer>
                        </PhotoProvider>
                    </div>
                }
            </div>
            {
                averageProbability && <div className="average_probability"
                    dangerouslySetInnerHTML={{ __html: averageProbability }} />
            }
            {
                tab &&
                <div className="tab-wrapper">
                    <div className="swiper-container" id="swiperTab">
                        <div className="swiper-wrapper tab" dangerouslySetInnerHTML={{ __html: tab }} ref={e => that.symptomsNursingTab = e} />
                    </div>
                </div>

            }
            {
                !hideFlag ? number_conditions && <div className="number_conditions"
                    dangerouslySetInnerHTML={{ __html: number_conditions }} /> : ''

            }
            {
                symptoms_nursing && <div className="symptoms_nursing"
                    dangerouslySetInnerHTML={{ __html: symptoms_nursing }} />
            }
        </>
    )
}

/**
 * @Description: 皮肤问题 综合 .sort((a, b) => b.level - a.level)
 */
export function skinProblemRendering(resData, that) {
    return resData.question.map((item, index) =>
        <div key={index}
            className=" item_shell">{ItemShell(item, Skin_question_item(item, resData.baseImage, resData.ytfile, resData.programQr, that, false))}</div>);
}

export function skinProblemRenderingDetectorReport(resData, hideFlag, type, that) {
    return resData.question.map((item, index) =>
        <div key={index}
            className=" item_shell">{ItemShellDetectorReport(item, hideFlag, type, Skin_question_item(item, resData.baseImage, resData.ytfile, resData.programQr, that, true, hideFlag))}</div>);
}

export function questionImgBox(resData) {
    let arr = resData.cgImgList || resData.question;
    // style={{marginLeft: `calc( 50% - ${system().fontSize*8.56/2}px)`}}
    return (
        <PhotoProvider>
            {
                arr.map((item, index) => {
                    return item.imgurl
                        ? (
                            <div className=" swiper-slide" key={index}>
                                <PhotoConsumer src={item.imgurl} intro={item.name}>
                                    <div className={"swiper-slide-box"}>
                                        <img className={"skin_question_avatar_img"}
                                            src={item.imgurl} alt="" />
                                        <div className={"name"}>{item.name}</div>
                                    </div>
                                </PhotoConsumer>
                            </div>
                        )
                        : ""
                }
                )
            }
        </PhotoProvider>
    )
}

/**
 * @Description: 弹出分享报告
 */
export function showShareImg(e, that) {
    // document.getElementById('Share_pic').style.display = 'block'
    // e.persist();
    // let dom = e ? e._dom.parentNode.parentNode.parentNode : document.getElementById("Share_pic");
    if (!that.state.sharePicOption) {
        html2canvas(e.currentTarget.parentNode.parentNode.parentNode, {
            useCORS: true,
            logging: false,
            backgroundColor: null
        }).then(function (canvas) {
            that.setState({
                sharePicOption: {
                    shadeClose: false,
                    style: 'background-color: rgba(0,0,0,0); width:100%',
                    content: `<div class="share_box"><img id="Bounced_Delete" onclick="
             removeNoSliding()" class="Bounced_Delete" src="${img_icon.deleteIcon}" alt=""><img class="canvas_img"
                                                                                                src="${canvas.toDataURL('image/png')}"
                                                                                                alt=""><span
            class="promptWords">${intl.get("长按保存图片分享好友")}</span></div>
`,
                    success: function (layero) {
                        window.removeNoSliding || (window.removeNoSliding = that.removeNoSliding.bind(that));
                    }
                }
            }, that.removeNoSliding.bind(that))
        });
    }
    else {
        document.body.addEventListener('touchmove', that.handleTouchMove, { passive: false }); // 禁止滑动
        layer.open(that.state.sharePicOption);
    }
}

