import React from 'react';
import "./index.scss";
import intl from 'react-intl-universal';
import Swiper from 'swiper';
import "swiper/css/swiper.min.css"
import {PhotoProvider} from "react-photo-view";
import 'react-photo-view/dist/index.css';
// import layer from "../../plugIn/layer_mobile/layer"
// import ReactEcharts from 'echarts-for-react';
// import html2canvas from 'html2canvas'
import {
    // degreeFotmat,
    // Skin_question_item,
    // ComprehensiveAnalysisResults,
    queryString,
    Avatar,
    ScoringDetails,
    SkinAnalysis,
    ItemShell,
    Share_pic,
    skinProblemRendering,
    showShareImg,
    questionImgBox,
    titleIsShow, glStyle
} from "../../plugIn/uils";

import {getWebFaceDetect} from '../../api/request';
import layer from "../../plugIn/layer_mobile/layer";

export default class ShareDetail extends React.Component {
    titleIsShow = titleIsShow("皮肤检测报告");

    constructor() {
        super();
        this.state = {
            resData: null,
            mySwiper: [],
            avatarBox: {
                strokeDasharray: 0,
                svgPer: 3.056 * this.titleIsShow.fontSize * 3.1415926 * 2
            }
        }
        const currentLocale = "zh-TW"; // Determine user's locale here
        intl.init({
            currentLocale,
            locales: {
                [currentLocale]: require(`./locales/${currentLocale}`)
            }
        });
    }

    componentDidMount() {
        document.title = this.titleIsShow.title;
        var formData = new FormData();  //创建一个forData
        formData.append('recordCode', queryString("cgid"));
        formData.append('language', queryString("language"));
        getWebFaceDetect(formData).then(resData => {
            this.setState({
                resData: resData.data
            });
        });
        document.body.addEventListener('touchmove', this.handleTouchMove, {passive: false}); // 禁止滑动
        glStyle();
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log("componentDidUpdate", this.sharePicOption)
        if (this.state.sharePicOption) {
            let that = this;
            that.mySwiper = new Swiper('#swiperBox .swiper-container', {
                // loop: true,
                initialSlide: 1,
                // width: this.titleIsShow.fontSize * 8.56,
                slidesPerView: 1.8,
                effect: 'coverflow',
                centeredSlides: true,
                coverflowEffect: {
                    rotate: 0,
                    stretch: 30,
                    depth: 560,
                    // modifier: 2,
                    // slideShadows: true
                },
                pagination: {
                    el: '.swiper-pagination',
                    type: 'progressbar',
                },
                on: {
                    slideChangeTransitionEnd: function () {
                        console.log(that, this.activeIndex, this.slides.length);//切换结束时，告诉我现在是第几个slide
                    },
                }
                // navigation: {
                //     nextEl: '.swiper-button-next',
                //     prevEl: '.swiper-button-prev',
                // },
            });
        }
    }

    handleTouchMove(event) {
        event.preventDefault();
    }

    removeMoreMask(event) {
        event.currentTarget.parentNode.previousSibling.classList = ["dermalogicatext"];
        event.currentTarget.parentNode.remove();
    }

    removeNoSliding() { // howShareImg 事件内 取消禁止滑动 关闭 加载动画
        document.body.removeEventListener("touchmove", this.handleTouchMove);
        layer.closeAll();
    }

    render() {
        let {resData, avatarBox} = this.state;
        return (
            this.state.resData ? (
                    this.state.sharePicOption ?
                        <>
                            <div id={"details-share"}>
                                <section
                                    id="Avatar">{Avatar(resData.clientImg, resData.totalScore, resData.type, resData.logo, avatarBox)}</section>
                                <section
                                    id="scoring_details">{ScoringDetails(resData.scoring, resData.totalScore)}</section>
                                <section id="Skin_analysis"
                                         className="item_shell">{ItemShell(resData.analysis, SkinAnalysis(resData))}</section>
                                <section id="swiperBox">
                                    <div className="swiper-container">
                                        <div className="swiper-wrapper">
                                            {questionImgBox(resData.question)}
                                        </div>
                                        <div className="swiper-pagination"/>
                                    </div>
                                    <button className={"left"} onClick={e => {
                                        this.mySwiper.slidePrev();
                                        e.preventDefault();
                                    }}/>
                                    <button className={"right"} onClick={e => {
                                        this.mySwiper.slideNext();
                                        e.preventDefault();
                                    }}/>
                                    <div className={"swiper-bottom-img"}>

                                    </div>
                                </section>
                                <section id="Skin_question">
                                    <PhotoProvider>
                                        {skinProblemRendering(resData, this)}
                                    </PhotoProvider>
                                </section>
                                <footer><img src={resData.logo} alt=""/><span>报告内容受拍摄影响，测肤分析仅供参考</span></footer>
                                <img className="share-icon btn" onClick={e => showShareImg(e, this)}
                                     src={require("../../static/img/Share-icon@2x.png")} alt=""
                                     style={{display: this.state.sharePicOption ? "block" : "none"}}/>
                            </div>
                        </>
                        :
                        <>
                            <div className={"mask"}/>
                            <div id="Share_pic">{Share_pic(resData, this)} </div>
                        </>
                )
                : layer.open({
                    type: 2,
                    shadeClose: false,
                })
        )
    }
}

// intl 国际化 语言代码
// const SUPPOER_LOCALES = [
//     {
//         name: "English",
//         value: "en-US"
//     },
//     {
//         name: "简体中文",
//         value: "zh-CN"
//     },
//     {
//         name: "繁體中文",
//         value: "zh-TW"
//     },
//     {
//         name: "français",
//         value: "fr-FR"
//     },
//     {
//         name: "日本の",
//         value: "ja-JP"
//     }
// ];
