import React from 'react';
import './App.scss';
import Index from "./pages/index/index"
import Detect from "./pages/detect"
import Detail from "./pages/detail"
import DetectorReport from "./pages/detectorReport"

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";

function App() {
    return (
        <Router>
            <>
                <Switch>
                    <Route path="/DetectorReport" component={DetectorReport}/>
                    <Route path="/Detail" component={Detail}/>
                    <Route path="/Detect" component={Detect}/>
                    <Route path="/index" component={Index}/>
                    <Redirect to="/index"/>
                </Switch>
            </>
        </Router>
    );
}

export default App;
