import React from 'react';
import "./index.scss";
import {glStyle, titleIsShow} from "../../plugIn/uils";
import {getFaceRequest} from '../../api/request';
import analysis_AI from "../../static/img/analysis_AI-icon@2x.png"
import portrait_img from "../../static/img/portrait_img.png"

export default class AboutUs extends React.Component {

    titleIsShow = titleIsShow("AI分析中");

    constructor() {
        super();
        this.state = {
            num: 0,
            testingProcess: [
                {name: "黑头检测中...", active: false},
                {name: "毛孔检测中...", active: false},
                {name: "色斑检测中...", active: false},
            ],
            resDataSuccess: false
        }
        glStyle();
    }

    upload(files) {
        var formData = new FormData();  //创建一个forData
        formData.append('file', files) //把file添加进去  name命名为img
        formData.append('language', 'zh')
        formData.append('sn', "1a:2b:3c:4d")
        formData.append('userId', '123456')
        getFaceRequest(formData).then(resData => {
            console.log(resData);
            resData.data.customerId && window.sessionStorage.setItem('customerId', resData.data.customerId);
            window.sessionStorage.setItem('recordCode', resData.data.recordCode);
            window.sessionStorage.setItem('webExecute', JSON.stringify(resData));
            window.sessionStorage.setItem('key', '');
            window.location.href = 'detail?device=2&cgid=' + resData.data.recordCode + '&language=zh&source=history';
        });

    }

    dataURItoBlob(dataURI) {
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]; // mime类型
        var byteString = atob(dataURI.split(',')[1]); //base64 解码
        var arrayBuffer = new ArrayBuffer(byteString.length); //创建缓冲数组
        var intArray = new Uint8Array(arrayBuffer); //创建视图

        for (var i = 0; i < byteString.length; i++) {
            intArray[i] = byteString.charCodeAt(i);
        }
        return new Blob([intArray], {type: mimeString});
    }

    componentDidMount() {
        document.title = this.titleIsShow.title;
        window.addEventListener('pageshow', function(event) {
            if(event.persisted){
                window.location.replace('index')
            }
        });
        let key = window.sessionStorage.getItem('key');
        // console.log(key);
        if (key) {
            this.upload(this.dataURItoBlob(key))
        } else {
            window.location.replace('index')
        };

        let Interval = setInterval(() => {
            let {testingProcess} = this.state;
            testingProcess[this.state.num].active = true;
            this.setState(prevState => ({
                ...testingProcess,
                num: ++prevState.num
            }))
            if (this.state.num === testingProcess.length) {
                clearInterval(Interval)
            }

        }, 1000)
    }

    render() {
        let {docHeight} = this.titleIsShow;
        return (
            <div id="test_page" style={{height: docHeight + "px"}}>
                <div className="title" style={{display: this.titleIsShow.display}}>AI分析中</div>
                <div className="score_box">
                    <svg className="svg" xmlns="http://www.w3.org/200/svg" height="150" width="110">
                        <circle className="grey_circle" cx="55" cy="55" r="50" fill="none" stroke="grey"
                                strokeWidth="5" strokeLinecap="round"></circle>
                        <circle className="progress_circle" id="J_progress_bar" cx="55" cy="55" r="50" fill="none"
                                strokeDasharray="" stroke="#4cc4fc" strokeWidth="5.74"></circle>
                    </svg>
                    <div className="scan_box">
                        <div className="scan"></div>
                        <img className="portrait" id="portrait" src={portrait_img} alt=""/>
                    </div>
                </div>
                <div className="calculate_score_bg"></div>
                <div className="calculate_score">
                    <div>综合得分计算...</div>
                    <div className="testing_process">
                        {this.state.testingProcess.map((item, index) => {
                            return (<span key={index} className={item.active ? "active" : ""}><img src={analysis_AI}
                                                                                                   alt=""/>{item.name}</span>)
                        })}
                    </div>
                </div>
                <div className="footer_mask"></div>
            </div>
        )
    }
}
