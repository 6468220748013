import djmLogo from "./img/DJM-Logo@2x.png" ;
import colorSkinModel from "./img/Color-card@2x.png";
import colorSkinMarking from "./img/Color-card-marking@2x.png";
import prompt from "./img/prompt@2x.png";
import canvas_bg from "./img/pie-bg.png";
import moreMask from "./img/more_mask@2x.png";
import moreMask2 from "./img/more_mask2@2x.png";
import moreMaskDown from "./img/more_mask_down@2x.png";
import beSolved from "./img/be-solved@2x.png";
import beBadly from "./img/be-badly@2x.png";
import beKeep from "./img/be-keep@2x.png";
import h5Icon from "./img/h5-icon@2x.png";
import deleteIcon from "./img/Bounced_Delete-icon@2x.png";
import worse from "./img/Detector_Worse-icon@2x.png";
import dianzan from "./img/Detector_dianzan-icon@2x.png";
import worseHand from "./img/Detector_Worse-icon-hand.png";
import dianzanHand from "./img/Detector_dianzan-icon-hand.png";
import avatarBg from "./img/Report_Top_Flow@2x.png";
import stain from "./img/Detector_Spots-icon@2x.png";
import wrinkle from "./img/Detector_Wrinkles-icon@2x.png";
import pore from "./img/Detector_Pores-icon@2x.png";
import spots from "./img/Detector_Spots-icon@2x.png";
import blain from "./img/Detector_blain-icon@2x.png";
// black from "./img/Detector_black-icon@2x.png";
import blackhead from "./img/Detector_Blackhead-icon@2x.png";
import blackEye from "./img/Detector_BlackEye-icon@2x.png";
import sensity from "./img/Detector_Color-icon@2x.png";
import spotsModel from "./img/spots-model@2x.png";
import poreModel from "./img/pore-model@2x.png";
import blainModel from "./img/blain-model@2x.png";
import skinModel from "./img/skin-model@2x.png";
import blackheadModel from "./img/black-model@2x.png";
import blackEyeModel from "./img/Black-eye-model@2x.png";
import wrinkleModel from "./img/wrinkles-model@2x.png";
import nasalTip from "./img/nasalTip@2x.png";
import wrinkles from "./img/wrinkles@2x.png";
import forehead from "./img/forehead@2x.png";
import onForehead from "./img/onForehead@2x.png";
import eyeGrain from "./img/eyeGrain@2x.png";
import chin from "./img/chin@2x.png";
import leftFace from "./img/leftFace@2x.png";
import rightFace from "./img/rightFace@2x.png";
import neuterSkin from "./img/Neuter_skin@2x.png";
import oilySkinTypes from "./img/Oily_skin_types@2x.png";
import drySkin from "./img/Dry_skin_icon@2x.png";
import pigment from "./img/pigment_icon@2x.png";
import vascular from "./img/vascular_icon@2x.png";
import payIcon from "./img/Buy-icon.png";
import handPayIcon from "./img/Buy-icon-hand.png";

export default {
    djmLogo,
    colorSkinModel,
    colorSkinMarking,
    prompt,
    canvas_bg,
    moreMask,
    moreMask2,
    moreMaskDown,
    beSolved,
    beBadly,
    beKeep,
    h5Icon,
    deleteIcon,
    worse,
    dianzan,
    worseHand,
    dianzanHand,
    avatarBg,
    stain,
    wrinkle,
    pore,
    spots,
    blain,
    blackhead,
    blackEye,
    sensity,
    spotsModel,
    poreModel,
    blainModel,
    skinModel,
    blackheadModel,
    blackEyeModel,
    wrinkleModel,
    dermalogicatext: {
        nasalTip,
        wrinkles,
        forehead,
        onForehead,
        eyeGrain,
        chin,
        leftFace,
        rightFace,
        neuterSkin,
        oilySkinTypes,
        drySkin,
        pigment,
        vascular,
    },
    payIcon,
    handPayIcon,
}
