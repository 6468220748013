import React from 'react';
import $ from 'jquery'
import "./index.scss";
import intl from 'react-intl-universal';
import Swiper from 'swiper';
import "swiper/css/swiper.min.css"
import 'react-photo-view/dist/index.css';
import html2canvas from "html2canvas";
import {
    PieRendering,
    queryString,
    Avatar,
    ScoringDetails,
    ItemShellDetectorReport,
    Share_pic,
    skinProblemRenderingDetectorReport,
    SkinAnalysisDetectorReport,
    showShareImg,
    questionImgBox,
    titleIsShow, glStyle,
    poreCanvansImg,
    getBase64Image,
    arrTrans
} from "../../plugIn/uils";
import { getWebMetisAPI } from '../../api/request';
import layer from "../../plugIn/layer_mobile/layer";
import logo from '../../static/img/DJM-Logo@2x.png'
export default class ShareDetail extends React.Component {
    titleIsShow = titleIsShow("皮肤检测报告");
    language = queryString("language");

    constructor() {
        super();
        this.getPoreEl = '';
        this.getBlackheadEl = '';
        this.imgEl = ''
        this.getPoreRef = (element) => {
            this.getPoreEl = element
        }
        this.getBlackheadRef = element => {
            this.getBlackheadEl = element
        }
        this.getImgRef = element => {
            this.imgEl = element
        }
        this.state = {
            resData: null,
            mySwiper: [],
            swiperActiveIndex: '',
            avatarBox: {
                strokeDasharray: 0,
                svgPer: 3.056 * this.titleIsShow.fontSize * 3.1415926 * 2
            },
            index: 0,
            hideFlag: false,
            clickIndex: 0,
            needFixed: false,
            poreImg: '',
            blackimg: ''

        }
        const currentLocale = this.language==='zh'?'zh':(this.language==='en'?'en':'en'); // Determine user's locale here,如果不是en也不是zh，那就默认en
        intl.init({
            currentLocale,
            locales: {
                [currentLocale]: require(`../../plugIn/language/${currentLocale}`)
            }
        });

    }

    componentDidMount() {
        document.title = this.language == 'zh' ? this.titleIsShow.title : 'Skin Analysis Report'
        var formData = new FormData();  //创建一个forData
        formData.append('device', queryString("device"));
        formData.append('cgid', queryString("cgid"));
        formData.append('language', this.language);
        getWebMetisAPI(formData).then(resData => {
            let that = this;
            resData.data.clientImg = that.CheckImgExists(resData.data.clientImg) ? resData.data.clientImg : require('../../static/img/touxiang.png')
            let image = new Image();
            image.setAttribute('crossOrigin', 'anonymous');
            image.src = resData.data.clientImg;
            image.onload = function () {
                let base64clientImg = getBase64Image(image);
                that.setState({
                    avatarShare: base64clientImg
                });
            }

            // that.getBas64(resData.data.cgImgList[1].imgurl).then(res => {
            //     that.setState({
            //         poreimg: res
            //     })
            //     html2canvas(that.getPoreEl, {
            //         useCORS: true,
            //         allowTaint: true,
            //         backgroundColor: null
            //     }).then(canvas => {
            //         let resImgUrl = canvas.toDataURL();
            //         resData.data.question.forEach(el => {
            //             if (el.tag === 'pore') {
            //                 el.imgurl = resImgUrl
            //             }
            //         })
            //         // that.setState({
            //         //     resData: resData.data,
            //         // })
            //     })

            // })
            // that.getBas64(resData.data.cgImgList[0].imgurl).then(res => {
            //     that.setState({
            //         blackimg: res
            //     })
            //     html2canvas(that.getBlackheadEl, {
            //         useCORS: true,
            //         allowTaint: true,
            //         backgroundColor: null
            //     }).then(canvas => {
            //         let resImgUrl = canvas.toDataURL();
            //         resData.data.question.forEach(el => {
            //             if (el.tag === 'blackhead') {
            //                 el.imgurl = resImgUrl
            //             }
            //         })
            //         that.setState({
            //             resData: resData.data,
            //         })
            //     })
            // })

            this.setState({
                swiperActiveIndex: resData.data.cgImgList.length,
                resData: resData.data,
                hideFlag: this.language === 'en' && resData.data.type === 'DBQ3-1' ? true : false // 当是 metis 并且是 英文 报告的时候 让 hideFlag 为true（用来判断某些模块的隐藏显示）
            })
            // exportImg(this.getPoreEl, 'poreBox', this)//绘制毛孔图
            // exportImg(this.getBlackheadEl, 'blackheadBox', this)//绘制黑头图
            resData.data.logo || (resData.data.logo = logo);
        });

        document.body.addEventListener('touchmove', this.handleTouchMove, { passive: false }); // 禁止滑动
        glStyle();

    }




    componentDidUpdate(prevProps, prevState) {
        let that = this;
        if (this.state.sharePicOption) {
            PieRendering("canvas_pie", that.state.resData.analysis, that.titleIsShow.fontSize).then(function (e) {
                PieRendering("canvas_text", that.state.resData.analysis, that.titleIsShow.fontSize)
            })
        }
        // 商品swiper
        that.goodsSwiper = new Swiper('#goodsSwiper .swiper-container', {
            autoHeight: true,
            pagination: {
                el: '.swiper-pagination'
            },
            on: {
                transitionEnd: function () {

                }
            }
        })
        // 需关注的问题tab滑动
        that.swiperTab = new Swiper('#swiperTab', {
            slidesPerView: 'auto',
            observer: true,//修改swiper自己或子元素时，自动初始化swiper
            observeParents: true,
            watchSlidesProgress: true,
            watchSlidesVisibility: true,
            slideToClickedSlide: true,//点击跟随滑动
            on: {
                tap: function (swiper, e) {
                    let swiperWidth = that.swiperTab.$el[0].clientWidth
                    let maxTranslate = that.swiperTab.maxTranslate();
                    let maxWidth = -maxTranslate + swiperWidth / 2
                    let slide = that.swiperTab.slides[that.swiperTab.clickedIndex]
                    let slideLeft = slide.offsetLeft
                    let slideWidth = slide.clientWidth
                    let slideCenter = slideLeft + slideWidth / 2
                    // 被点击slide的中心点

                    that.swiperTab.setTransition(300)

                    if (slideCenter < swiperWidth / 2) {

                        that.swiperTab.setTranslate(0)

                    } else if (slideCenter > maxWidth) {

                        that.swiperTab.setTranslate(maxTranslate)

                    } else {

                        let nowTlanslate = slideCenter - swiperWidth / 2

                        that.swiperTab.setTranslate(-nowTlanslate)

                    }

                    $("#swiperTab .active").removeClass('active')

                    $("#swiperTab .swiper-slide").eq(that.symptomsNursingSwiper.activeIndex).addClass('active')
                    that.symptomsNursingSwiper.slideTo(this.clickedIndex)
                }
            }
        })




        that.symptomsNursingSwiper = new Swiper('#symptoms_nursing_swiper', {
            speed: 300,
            observer: true,
            observeParents: true,
            observeSlideChildren: true,
            noSwipingClass: 'g-swiper',
            on: {
                // init: function () {
                //     that.symptomsNursingTab && that.symptomsNursingTab.childNodes.forEach((item, index) => {
                //         item.onclick = () => {
                //             this.slideTo(item.getAttribute("data-index"), 500, true);
                //         }
                //     })
                // },
                // transitionStart: function () {
                //     that.symptomsNursingTab.childNodes.forEach((item, index) => {
                //         if (index === this.activeIndex) {
                //             item.classList = "active";
                //         } else {
                //             item.classList = "";
                //         }
                //     })
                // }
                transitionStart: function () {
                    that.updateNavPosition()
                }

            }
        });
        that.mySwiper = new Swiper('#swiperBox .swiper-container', {
            loop: false,
            lazy: {
                loadPrevNext: true,
            },
            initialSlide: 1,
            slidesPerView: 1.8,
            effect: 'coverflow',
            centeredSlides: true,
            coverflowEffect: {
                rotate: 0,
                stretch: 30,
                depth: 560,
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'progressbar',
            },
            on: {
                transitionEnd: function () {
                    this.active = this.realIndex
                    let activeIndex = this.active + 1;
                    that.swiperActiveDom.innerText = (activeIndex < 10 ? "0" + activeIndex : activeIndex) + "/" + that.state.swiperActiveIndex
                },
            }
            // navigation: {
            //     nextEl: '.swiper-button-next',
            //     prevEl: '.swiper-button-prev',
            // },
        });

    }

    getBas64(url, outputFormat = 'image/jpeg') {
        return new Promise(function (resolve, reject) {
            let canvas = document.createElement('CANVAS'),
                ctx = canvas.getContext('2d'),
                img = new Image;

            img.crossOrigin = 'Anonymous'; // 重点！设置image对象可跨域请求
            img.onload = function () {
                canvas.height = img.height;
                canvas.width = img.width;
                ctx.drawImage(img, 0, 0);
                let dataURL = canvas.toDataURL(outputFormat);
                canvas = null;
                resolve(dataURL);
            };
            img.src = url + '?jinhaoyang_djm=' + new Date().getTime();
        })
    }


    //判断图片是否存在
    CheckImgExists(imgurl) {
        var oreq = new XMLHttpRequest()
        oreq.open("Get", imgurl, false);
        oreq.send();
        if (oreq.status == 404) {
            return false
        } else {
            return true;
        }
    }

    updateNavPosition() {
        let that = this;
        $('#swiperTab .active').removeClass('active')
        var activeNav = $('#swiperTab .swiper-slide').eq(that.symptomsNursingSwiper.activeIndex).addClass('active');
        if (!activeNav.hasClass('swiper-slide-visible')) {
            if (activeNav.index() > that.swiperTab.activeIndex) {
                // var thumbsPerNav = Math.floor(that.swiperTab.width / activeNav.width()) - 1
                that.swiperTab.slideTo(activeNav.index())
            } else {
                that.swiperTab.slideTo(activeNav.index())
            }
        }
        // else if(activeNav.index() === 0){
        //     that.swiperTab.slideTo(0)
        // }
    }

    handleTouchMove(event) {
        event.preventDefault();
    }

    removeMoreMask(event) {
        event.currentTarget.parentNode.previousSibling.classList = ["dermalogicatext"];
        event.currentTarget.parentNode.remove();
    }
    /*
     *@description:商品图片预览
     *@author: 张莹
     *@date: 2020-08-10 12:05:11
     *@version: V1.0.5
    */
    preview(event) {
        let img = event.currentTarget.childNodes[1].src
        let perview = `
                        <div class="perview_bg_box">
                            <img src="${img}"></img>
                        </div>
                      `
        layer.open({
            content: perview
            , style: 'border:none;' //自定风格
        })
    }

    removeNoSliding() { // howShareImg 事件内 取消禁止滑动 关闭 加载动画
        document.body.removeEventListener("touchmove", this.handleTouchMove);
        layer.closeAll();
    }



    render() {
        let { resData, avatarBox, hideFlag, avatarShare, poreimg, blackimg } = this.state;
        return (
            <div>
                {

                    this.state.resData ? (
                        <div>
                            {/* <div className="canvans_box" ref={this.getPoreRef}>
                                {
                                    <div>
                                        <img src={poreimg} alt="" ref={this.getImgRef} crossOrigin="anonymous" />
                                    </div>

                                }
                                {
                                    JSON.parse(resData.drawing.pore).map((item, index) => {
                                        return (
                                            <i className="pore" key={index} style={{ top: item[0] / 5.95 / 20 + 'rem', left: item[1] / 5.95 / 20 + 'rem' }}></i>
                                        )
                                    })
                                }
                            </div>
                            <div className="canvans_box" ref={this.getBlackheadRef}>
                                {
                                    <div>
                                        <img src={blackimg} alt="" ref={this.getImgRef} crossOrigin="anonymous" />
                                    </div>

                                }
                                {
                                    arrTrans(2, JSON.parse(resData.drawing.blackhead)).map((item, index) => {
                                        return (
                                            <i className="blackhead" key={index} style={{ left: item[0] / 5.95 / 20 + 'rem', top: item[1] / 5.95 / 20 + 'rem' }}></i>
                                        )
                                    })

                                }
                            </div> */}
                            {/* <div className="canvans_box" ref={this.getPoreRef}>{poreCanvansImg('pore', this)}</div> */}
                            {/* <div className="canvans_box" ref={this.getBlackheadRef}>{poreCanvansImg('blackhead', this)}</div> */}
                            {
                                this.state.sharePicOption ? (
                                    <div id={"Share_details"}>
                                        <section
                                            id="Avatar">{Avatar(resData.clientImg, resData.totalScore, resData.type, resData.logo, avatarBox)}</section>
                                        <section
                                            id="scoring_details">{ScoringDetails(resData.scoring, resData.totalScore)}</section>
                                        <section id="Skin_analysis"
                                            className={(this.language === 'zh' && resData.type === 'DBQ3-1') || (this.language === 'zh' && resData.type === 'DBQ3-3') || (this.language === 'en' && resData.type === 'DBQ3-1') ? 'item_shell' : 'hide'}>{ItemShellDetectorReport(resData.analysis, hideFlag, 'Skin_analysis', SkinAnalysisDetectorReport(resData))}</section>
                                        <section id="swiperBox">
                                            <div className="swiper-container">
                                                <div className="swiper-wrapper">
                                                    {questionImgBox(resData)}
                                                </div>
                                                <div className="swiper-pagination" />
                                            </div>
                                            <button className={"left"} onClick={e => {
                                                this.mySwiper.slidePrev();
                                                e.preventDefault();
                                            }} />
                                            <button className={"right"} onClick={e => {
                                                this.mySwiper.slideNext();
                                                e.preventDefault();
                                            }} />
                                            <div className={"swiper-bottom-img"}>
                                                <span className={"swiper-active-index"} ref={e => this.swiperActiveDom = e}></span>
                                            </div>
                                        </section>
                                        <section id="Skin_question">
                                            {skinProblemRenderingDetectorReport(resData, hideFlag, 'Skin_question', this)}
                                        </section>
                                        <footer><img src={resData.logo} alt="" /><span>{intl.get('报告内容受拍摄影响，测肤分析仅供参考')}</span>
                                        </footer>
                                        {
                                            this.language == 'zh' ? (
                                                <img className="share-icon btn" onClick={e => showShareImg(e, this)}
                                                    src={require("../../static/img/Share-icon@2x.png")} alt=""
                                                    style={{ display: this.state.sharePicOption ? "block" : "none" }} />
                                            ) : (<></>)
                                        }


                                    </div>
                                ) : (
                                        <div>
                                            <div className={"mask"} />
                                            <div id="Share_pic" className={"detectorReport"}>{Share_pic(resData, avatarShare, this, true)} </div>
                                        </div>
                                    )
                            }
                        </div>

                    ) : layer.open({
                        type: 2,
                        shadeClose: false,
                        shade: 'background-color: rgba(0,0,0,1)'
                    })
                }
            </div>
        )
    }
}

